
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import { Box } from '@chakra-ui/react';
import Container from 'components/common/Container';
import LoginForm from 'components/common/UserProfile/LoginForm';
import PreRegisterForm from 'components/common/UserProfile/PreRegisterForm';
import FadeIn from 'components/transitions/FadeIn';
import Head from 'next/head';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Tracking from 'utils/tracking';

export default function Login() {
  const router = useRouter();

  useEffect(() => {
    Tracking.pageView(
      'Login-Subscribe',
      router.locale,
      `/${router.locale}${router.asPath}`,
      undefined,
      undefined,
      undefined,
      'Login Page'
    );
  }, [router.locale]);

  useEffect(() => {
    Tracking.viewLogin();
  }, []);
  return (
    <>
      <FadeIn>
        <Head>
          <title>Login Page</title>
        </Head>
        <Box w="100%">
          <Container>
            <Box textAlign="center" w="70%" m="auto">
              {/* <Box as="p" marginTop="50px" p="20px" border="2px solid #909090" borderRadius="10px">
                {t('notificationLogin')}
              </Box> */}
            </Box>
            <Box display={{ base: 'block', lg: 'flex' }} margin={{ base: '35px 0', lg: '70px 0' }}>
              <LoginForm />
              <PreRegisterForm />
            </Box>
          </Container>
        </Box>
      </FadeIn>
    </>
  );
}


    export async function getStaticProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/login/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  