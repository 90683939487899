import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  Box,
  useToast,
  ModalFooter,
  ModalContent,
  Button,
} from '@chakra-ui/react';
import FormInput from '../Forms/FormInput';
import useTranslation from 'next-translate/useTranslation';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { getErrorMessage } from 'utils/helpers';
import { getPinValidation } from 'services/user';
import { useRouter } from 'next/router';
import { CloseIcon } from '@chakra-ui/icons';

const PinValidation = ({ isOpen, onClose }) => {
  const router = useRouter();
  const { t } = useTranslation('user');
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const toast = useToast();

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const { data: pinData } = await getPinValidation(data?.pin);

      await localStorage.setItem('existingUser', JSON.stringify(pinData));
      setLoading(false);
      await router.push('/register');
    } catch (err) {
      toast({
        description: getErrorMessage(err),
        position: 'top',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Modal size={'2xl'} isCentered={true} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent as={'form'} onSubmit={handleSubmit(onSubmit)} noValidate autocomplete="off" alignItems={'center'}>
          <ModalHeader width={'100%'}>
            <Box alignItems={'center'} display={'flex'} justifyContent={'space-between'} width={'100%'}>
              <Box />
              {t('confirmEmail')}
              <CloseIcon cursor={'pointer'} width={'15px'} height={'15px'} onClick={onClose} />
            </Box>
          </ModalHeader>
          <ModalBody pb={6}>
            <Box width={{ base: '300px', lg: '400px' }}>{t('confirmEmailMessage')}</Box>
            <Box mt="1rem">
              <FormInput
                inputProps={{ ...register('pin', { required: t('common:requiredField') }) }}
                controlProps={{ mb: '20px' }}
                id="pin"
                label={t('confirmEmailInput')}
                error={errors?.pin?.message}
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button type={'submit'} colorScheme="blue" isLoading={loading} mr={3}>
              {t('confirm')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PinValidation;
