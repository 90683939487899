import React from 'react';
import { useState } from 'react';
import 'node_modules/react-date-picker/dist/DatePicker.css';
import 'node_modules/react-calendar/dist/Calendar.css';
import { Box, Button, Text } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';
import FormInput from 'components/common/Forms/FormInput';
import useTranslation from 'next-translate/useTranslation';
import NextLink from 'next/link';
import { getRegistrationToken } from 'services/user';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import FormNumber from 'components/common/Forms/FormNumber';
import { useDisclosure } from '@chakra-ui/react';
import { getErrorMessage } from 'utils/helpers';
import { getPinValidation } from 'services/user';
import PinValidation from './PinValidation';
import { PATTERN_EMAIL } from 'utils/constants';

export default function RegisterForm() {
  const { t } = useTranslation('user');
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const router = useRouter();
  const [pin, setPin] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  let registerPath = '/register';

  if (router.query.destination) {
    registerPath = `/register?destination=${router.query.destination}`;
  }
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await getRegistrationToken(router.locale, data?.email, data?.telephone);

      setPin(response.data);
      onOpen();
    } catch (err) {
      toast({
        description: getErrorMessage(err),
        position: 'top',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <>
      <Box
        as={'form'}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autocomplete="off"
        order="0"
        w={{ base: '100%', lg: '50%' }}
        borderRight={{ base: 'none', lg: 'solid 1px lightgrey' }}
        borderBottom={{ base: 'solid 1px lightgrey', lg: 'none' }}
        paddingRight={{ base: '0', lg: '65px' }}
        paddingBottom={{ base: '65px', lg: '0' }}
      >
        <Text as="h1" fontSize="36px" lineHeight="45px" textAlign="center" fontWeight="bold" p="40px 0">
          {t('newCustomersLabel')}
        </Text>
        <Box>
          <Text mb="20px" lineHeight="30px">
            {t('newCustomers')}
          </Text>
          <Text mb="20px" lineHeight="30px">
            {t('fill')}
          </Text>
          <Box marginBottom="20px">
            <FormInput
              inputProps={{
                type: 'email',
                ...register('email', {
                  required: t('common:requiredField'),
                  pattern: PATTERN_EMAIL,
                }),
              }}
              id="mail"
              isRequired
              marginRight="20px"
              label={t('common:email')}
              error={errors?.email?.message}
            />
            <Box mt="10px">
              <FormInput
                isRequired
                inputProps={{
                  ...register('telephone', {
                    required: t('common:requiredField'),
                    minLength: {
                      value: 8,
                      message: t('telerestriction'), // JS only: <p>error message</p> TS only support string
                    },
                    maxLength: {
                      value: 15,
                      message: t('telerestriction'), // JS only: <p>error message</p> TS only support string
                    },
                  }),
                }}
                controlProps={{ mb: '20px' }}
                id="telephone"
                label={t('form:mobile')}
                error={errors?.telephone?.message}
              />
            </Box>
          </Box>
          <Box mt="40px">
            <Button type={'submit'} w={'250px'} display={'flex'} variant={'outlineInverted'} m="auto">
              {t('register')}
            </Button>
          </Box>
        </Box>
      </Box>
      <PinValidation pin={pin} onOpen={onOpen} onClose={onClose} isOpen={isOpen} />
    </>
  );
}
