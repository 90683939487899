import { FormControl, FormLabel, NumberInput, FormErrorMessage, NumberInputField } from '@chakra-ui/react';

export default function FormNmber({
  label,
  id,
  isRequired,
  isDisabled,
  isReadOnly,
  labelProps,
  placeholder,
  inputProps,
  controlProps,
  error,
  value,
}) {
  return (
    <FormControl
      isRequired={isRequired}
      className={error && 'errorClass'}
      isReadOnly={isReadOnly}
      marginRight="20px"
      id={id}
      isInvalid={error}
      {...controlProps}
    >
      <FormLabel textStyle="labelText" marginBottom="0" {...labelProps}>
        {label}
      </FormLabel>

      <NumberInput isDisabled={isDisabled} isInvalid={error} isReadOnly={isReadOnly}>
        <NumberInputField
          borderRadius="0"
          borderColor={'text.secondary'}
          placeholder={placeholder}
          value={value}
          _disabled={{ color: 'black' }}
          {...inputProps}
        />
      </NumberInput>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
}
